/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { APIReturnTypes, handleError, handleResponse } from "./utils";
import { definitions } from "../../types/swagger/ratingServiceTypings";

type ProvisionedUnit = definitions["ProvisionedUnitV1"];
const basePath = "api/ProvisionedUnit";

export const saveProvisionedUnits = async (
  provisionedUnits: ProvisionedUnit[]
): Promise<APIReturnTypes> => {
  return await fetch(basePath + "/SaveProvisionedUnits", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(provisionedUnits),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteProvisionedUnits = async (
  tenantId: string,
  idsToDelete: string[]
): Promise<APIReturnTypes> => {
  return await fetch(
    basePath + "/DeleteProvisionedUnits?tenantId=" + tenantId,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
      body: JSON.stringify(idsToDelete),
    }
  )
    .then(handleResponse)
    .catch(handleError);
};
