/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type FlatRatingDataV1 = definitions["FlatRatingDataV1"];
type JsonHierarchy = definitions["JsonHierarchy"];

export enum ActionTypesEnum {
  SET_STATIC_QUESTIONS = "SET_STATIC_QUESTIONS",
  SET_STATIC_QUESTION_STATE_LINK_IDS = "SET_STATIC_QUESTION_STATE_LINK_IDS",
  SET_JSON_HIERARCHY = "SET_JSON_HIERARCHY",
}

export interface StaticQuestionsState {
  staticQuestionFRD: FlatRatingDataV1[];
  staticQuestionStateLinkIds: { [state: string]: string[] };
  jsonHierarchyModel: JsonHierarchy[];
}

interface SetStaticQuestionsAction {
  type: ActionTypesEnum.SET_STATIC_QUESTIONS;
  payload: FlatRatingDataV1[];
}

interface SetStaticQuestionStateLinkIdsAction {
  type: ActionTypesEnum.SET_STATIC_QUESTION_STATE_LINK_IDS;
  payload: { state: string; staticQuestionStateLinkIds: string[] };
}

interface SetJsonHierarchyAction {
  type: ActionTypesEnum.SET_JSON_HIERARCHY;
  payload: JsonHierarchy[];
}

export type StaticQuestionActionTypes =
  | SetStaticQuestionsAction
  | SetStaticQuestionStateLinkIdsAction
  | SetJsonHierarchyAction;
