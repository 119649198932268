/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { definitions } from "../../../types/swagger/ratingServiceTypings";

export type TenantInfo = definitions["TenantInfoV1"];

export enum ActionTypesEnum {
  GET_ALL_TENANTS = "GET_ALL_TENANTS",
  ADD_NEW_TENANT = "ADD_NEW_TENANTS",
}

export interface ProvisioningState {
  allTenantInfo: TenantInfo[];
}

export interface GetTenantInfoAction {
  type: typeof ActionTypesEnum.GET_ALL_TENANTS;
  payload: TenantInfo[];
}

export interface AddTenantInfoAction {
  type: typeof ActionTypesEnum.ADD_NEW_TENANT;
  payload: TenantInfo;
}

export type ProvisioningActionTypes = GetTenantInfoAction | AddTenantInfoAction;
