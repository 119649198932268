/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import * as ratingUnitApi from "../../api/ratingUnit";
import * as provisionedUnitApi from "../../api/provisionedUnit";
import { RatingUnitActionTypes } from "./types";
import {
  setRatingUnits,
  setProvisionedUnitsForTenantAction,
  saveProvisionedUnitsAction,
  removeProvisionedUnitsAction,
} from "./actions";
import { Dispatch } from "redux";
import { isTypeOf, notEmpty } from "../../../util/typeGuards";
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type RatingUnit = definitions["RatingUnitV1"];
type ProvisionedUnit = definitions["ProvisionedUnitV1"];

export const getRatingUnitsController = () => {
  return async (dispatch: Dispatch<RatingUnitActionTypes>): Promise<void> => {
    return await ratingUnitApi
      .getRatingUnits()
      .then((ratingUnits) => {
        if (
          !Array.isArray(ratingUnits) ||
          (ratingUnits.length > 0 &&
            !isTypeOf<RatingUnit>(ratingUnits[0], ["planId"]))
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(setRatingUnits(ratingUnits as RatingUnit[]));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getProvisionedUnitsByTenantController = (tenantId: string) => {
  return async (dispatch: Dispatch<RatingUnitActionTypes>): Promise<void> => {
    return await ratingUnitApi
      .getProvisionedUnitsByTenant(tenantId)
      .then((ids) => {
        if (!Array.isArray(ids)) {
          throw new Error("Error communicating with API");
        }
        dispatch(setProvisionedUnitsForTenantAction(tenantId, ids as string[]));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const saveProvisionedUnitsController = (
  tenantId: string,
  provisionedUnits: ProvisionedUnit[]
) => {
  return async (dispatch: Dispatch<RatingUnitActionTypes>): Promise<void> => {
    return await provisionedUnitApi
      .saveProvisionedUnits(provisionedUnits)
      .then((pus) => {
        if (
          !Array.isArray(pus) ||
          (pus.length > 0 && !isTypeOf<ProvisionedUnit>(pus[0], ["tenantId"]))
        ) {
          throw new Error("Error communicating with API");
        }
        dispatch(
          saveProvisionedUnitsAction(
            tenantId,
            (pus as ProvisionedUnit[])
              .map((pu) => pu.ratingUnitId)
              .filter((x) => notEmpty<string>(x)) as string[]
          )
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteProvisionedUnitsController = (
  tenantId: string,
  idsToDelete: string[]
) => {
  return async (dispatch: Dispatch<RatingUnitActionTypes>): Promise<void> => {
    return await provisionedUnitApi
      .deleteProvisionedUnits(tenantId, idsToDelete)
      .then(() => {
        dispatch(removeProvisionedUnitsAction(tenantId, idsToDelete));
      })
      .catch((error) => {
        throw error;
      });
  };
};
