/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { initialState as userAuthInitialState } from "./userAuth/initialState";
import { initialState as provisioningInitialState } from "./provisioning/initialState";
import { initialState as staticQuestionsInitialState } from "./staticQuestions/initialState";
import { initialState as companyQuestionsInitialState } from "./companyQuestions/initialState";
import { initialState as ratingUnitInitialState } from "./ratingUnits/initialState";
import { initialState as ratingDataSampleState } from "./ratingSample/initialState";
import { initialState as ratingArtifactState } from "./artifact/initialState";
import { initialState as ratingDataPacketState } from "./ratingDataPacket/initialState";
import { RouterState } from "connected-react-router";
import { InitialStateType } from "./rootType";

export const initialState: InitialStateType = {
  // This is needed because what's happening is with Typescript, the redux state tree has to match the reducer tree. And the reducer tree contains router. However, if you pass a state to router, you get a runtime error
  // https://github.com/supasate/connected-react-router/issues/312#issuecomment-558329903
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: (undefined as any) as RouterState,
  userAuth: userAuthInitialState,
  staticQuestions: staticQuestionsInitialState,
  companyQuestions: companyQuestionsInitialState,
  ratingUnits: ratingUnitInitialState,
  provisioning: provisioningInitialState,
  ratingDataSamples: ratingDataSampleState,
  artifacts: ratingArtifactState,
  ratingDataPackets: ratingDataPacketState,
};
