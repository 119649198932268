/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ActionTypesEnum, CompanyQuestionActionTypes } from "./types";
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type FlatRatingDataV1 = definitions["FlatRatingDataV1"];

export const addCompanyQuestion = (
  flatRatingData: FlatRatingDataV1
): CompanyQuestionActionTypes => {
  return {
    type: ActionTypesEnum.ADD_COMPANY_QUESTION,
    payload: flatRatingData,
  };
};

export const addCompanyQuestionArray = (
  flatRatingDataArr: FlatRatingDataV1[]
): CompanyQuestionActionTypes => {
  return {
    type: ActionTypesEnum.ADD_COMPANY_QUESTION_ARRAY,
    payload: flatRatingDataArr,
  };
};

export const addRequiredStaticQuestion = (
  flatRatingData: FlatRatingDataV1
): CompanyQuestionActionTypes => {
  return {
    type: ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION,
    payload: flatRatingData,
  };
};

export const addRequiredStaticQuestionArray = (
  flatRatingDataArr: FlatRatingDataV1[]
): CompanyQuestionActionTypes => {
  return {
    type: ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION_ARRAY,
    payload: flatRatingDataArr,
  };
};
