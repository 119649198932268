/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { Nav, Navbar, Container, NavDropdown, Button } from "react-bootstrap";
import React, { Component, ReactElement } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Pages } from "../../types/enums/pages";
import { RouteComponentProps, withRouter } from "react-router";
import { getPagePath, RouteMap } from "../../routes/RouteComponent";
import "./header.css";
import classNames from "classnames";
import { logoutController } from "../../app/store/userAuth/controller";
import { ConnectedProps, connect } from "react-redux";

const getPageName = (page: Pages): string => {
  switch (page) {
    case Pages.LOGIN:
      return "Vertafore Rating API Utility Login";
    case Pages.REFERENCE:
      return "Vertafore Rating API Reference";
    case Pages.ARTIFACTS:
      return "Vertafore Rating API Artifact Viewer";
    case Pages.MIGRATION:
      return "Vertafore Rating API Migration Tool";
    case Pages.PROVISIONING:
      return "Vertafore Rating API Provisioning Tool";
    case Pages.UNKNOWN:
    default:
      return "Vertafore Rating API Utility";
  }
};

interface HeaderProps extends RouteComponentProps {
  userName?: string;
  showAdmin: boolean;
  showRef: boolean;
}

const mapStateToProps = undefined;

const mapDispatchToProps = {
  logoutController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HeaderProps;

class HeaderComponent extends Component<Props> {
  getCurrentPage = (): Pages => {
    for (const key of Array.from(RouteMap.keys())) {
      const path = RouteMap.get(key);
      if (path === undefined) {
        continue;
      }
      if (this.props.location.pathname.includes(path)) {
        return key;
      }
    }
    return Pages.UNKNOWN;
  };

  render = (): ReactElement => {
    const navClasses = classNames({
      "btn-primary": true,
      "my-auto": true,
      navdropdown: true,
    });

    return (
      <Container fluid className={"no-gutters mx-0 px-0"}>
        {this.props.userName !== undefined && this.props.userName !== "" ? (
          <Navbar
            bg="dark"
            expand="lg"
            className="justify-content-between"
            variant="dark"
          >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {this.props.showRef ? (
                  <LinkContainer to={getPagePath(Pages.REFERENCE)}>
                    <Nav.Link>
                      <Button className="orange-btn">Reference</Button>
                    </Nav.Link>
                  </LinkContainer>
                ) : (
                  <div />
                )}
                {this.props.showAdmin ? (
                  <NavDropdown
                    className={navClasses}
                    title={<span className="text-white">Admin Tool</span>}
                    id="basic-nav-dropdown/"
                  >
                    <LinkContainer to={getPagePath(Pages.PROVISIONING)}>
                      <NavDropdown.Item className="adminnav">
                        Provisioning
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={getPagePath(Pages.MIGRATION)}>
                      <NavDropdown.Item className="adminnav">
                        Migration
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={getPagePath(Pages.ARTIFACTS)}>
                      <NavDropdown.Item className="adminnav">
                        Artifacts
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                ) : (
                  <div />
                )}
              </Nav>
              <Nav className="mr-auto justify-content-center">
                <Navbar.Brand>
                  {getPageName(this.getCurrentPage())}
                </Navbar.Brand>
              </Nav>

              {this.props.userName !== undefined &&
              this.props.userName !== "" ? (
                <div>
                  <div className="justify-content-right">
                    <div className="signedInas">
                      Signed in as: {this.props.userName}
                      {""}
                      <Button
                        className="orange-btn ml-3"
                        onClick={async () => {
                          await this.props.logoutController();
                          this.props.history.push(getPagePath(Pages.LOGIN));
                        }}
                      >
                        Log Out
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </Navbar.Collapse>
          </Navbar>
        ) : (
          ""
        )}
      </Container>
    );
  };
}

export default withRouter(connector(HeaderComponent));
