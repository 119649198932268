/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import {
  ActionTypesEnum,
  CompanyQuestionActionTypes,
  CompanyQuestionState,
} from "./types";
import { initialState } from "./initialState";
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type FlatRatingDataV1 = definitions["FlatRatingDataV1"];

export const companyQuestionReducer = (
  // Redux requires state to be the first parameter
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: CompanyQuestionActionTypes
): CompanyQuestionState => {
  switch (action.type) {
    case ActionTypesEnum.ADD_COMPANY_QUESTION:
      return {
        ...state,
        companyQuestionFRD: [...state.companyQuestionFRD, action.payload],
      };
    case ActionTypesEnum.ADD_COMPANY_QUESTION_ARRAY: {
      const newArr: FlatRatingDataV1[] = [];
      // Only add it if it hasn't been added already
      action.payload.forEach((x) => {
        if (!state.companyQuestionFRD.some((y) => y.id === x.id)) {
          newArr.push(x);
        }
      });
      return {
        ...state,
        companyQuestionFRD: [...state.companyQuestionFRD, ...newArr],
      };
    }
    case ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION:
      return {
        ...state,
        requiredStaticQuestionFRD: [
          ...state.requiredStaticQuestionFRD,
          action.payload,
        ],
      };
    case ActionTypesEnum.ADD_REQUIRED_STATIC_QUESTION_ARRAY: {
      const newArr: FlatRatingDataV1[] = [];
      // Only add if it hasn't been added already
      action.payload.forEach((x) => {
        if (!state.requiredStaticQuestionFRD.some((y) => y.id === x.id)) {
          newArr.push(x);
        }
      });
      return {
        ...state,
        requiredStaticQuestionFRD: [
          ...state.requiredStaticQuestionFRD,
          ...newArr,
        ],
      };
    }

    default:
      return state;
  }
};
