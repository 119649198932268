/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import {
  getRatingUnitsController,
  getProvisionedUnitsByTenantController,
  deleteProvisionedUnitsController,
  saveProvisionedUnitsController,
} from "../../app/store/ratingUnits/controller";

import { definitions } from "../../types/swagger/ratingServiceTypings";
import CompanyView from "../companyQuestions/companyView";
import { getStateStringArray } from "../../types/enums/states";
import ProvisioningReviewComponent from "./provisioningReview";
import { TenantInfo } from "../../app/store/provisioning/types";
import { notEmpty } from "../../util/typeGuards";
import "./provisioning.css";

type RatingUnit = definitions["RatingUnitV1"];
type ProvisionedUnit = definitions["ProvisionedUnitV1"];

interface State {
  loading: boolean;
  selectedProvisionedRUs: RatingUnit[];
  allChecked: boolean;
  displayedRatingUnits: RatingUnit[];
  selectedState: string;
  selectedLob: string;
  showReviewScreen: boolean;
  filterText: string;
  loadingSave: boolean;
}

interface Props {
  selectedTenant: TenantInfo | undefined; // placeholder
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  ratingUnits: state.ratingUnits.ratingUnits,
  provisionedUnits: state.ratingUnits.provisionedUnits,
});

const mapDispatchToProps = {
  getRatingUnitsController,
  getProvisionedUnitsByTenantController,
  saveProvisionedUnitsController,
  deleteProvisionedUnitsController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FinalProps = PropsFromRedux & Props;

class ProvisioningCompanySelectComponent extends Component<FinalProps, State> {
  constructor(props: FinalProps) {
    super(props);
    this.state = {
      loading: false,
      selectedProvisionedRUs: [],
      allChecked: false,
      displayedRatingUnits: [],
      selectedState: "ALL",
      selectedLob: "ALL",
      showReviewScreen: false,
      filterText: "",
      loadingSave: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    await this.loadCompanies();
  };

  componentDidUpdate = async (
    prevProps: FinalProps,
    prevState: State
  ): Promise<void> => {
    if (
      prevProps.selectedTenant?.tenantId !==
        this.props.selectedTenant?.tenantId &&
      !this.state.loading
    ) {
      await this.loadCompanies();
    } else if (
      prevState.selectedState !== this.state.selectedState ||
      prevState.selectedLob !== this.state.selectedLob ||
      prevState.filterText !== this.state.filterText
    ) {
      const displayedRUs = this.getDisplayedRatingUnits();
      this.setState({
        displayedRatingUnits: displayedRUs,
      });
    }
  };

  loadCompanies = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.pullCompanies();
  };

  pullCompanies = async (): Promise<void> => {
    if (this.props.ratingUnits.length === 0) {
      await this.props.getRatingUnitsController();
    }
    if (
      this.props.selectedTenant?.tenantId != null &&
      this.props.provisionedUnits[this.props.selectedTenant.tenantId] == null
    ) {
      await this.props.getProvisionedUnitsByTenantController(
        this.props.selectedTenant?.tenantId
      );
    }

    const displayedRUs = this.getDisplayedRatingUnits();
    const selectedProvisionedRUs = this.getProvisionedUnits();

    this.setState({
      loading: false,
      selectedProvisionedRUs: selectedProvisionedRUs,
      displayedRatingUnits: displayedRUs,
    });
  };

  getDisplayedRatingUnits = (): RatingUnit[] => {
    let displayedRUs: RatingUnit[];
    if (this.state.selectedState !== "ALL") {
      displayedRUs = this.props.ratingUnits.filter(
        (ru) => ru.state === this.state.selectedState
      );
    } else {
      displayedRUs = this.props.ratingUnits;
    }
    if (this.state.filterText !== "") {
      displayedRUs = displayedRUs.filter((ru) =>
        ru.name
          ?.toLocaleUpperCase()
          .includes(this.state.filterText.toLocaleUpperCase())
      );
    }

    if (this.state.selectedLob !== "ALL") {
      displayedRUs = displayedRUs.filter(
        (x) => x.lineOfBusiness === this.state.selectedLob
      );
    }

    let checkAll: boolean = true;

    displayedRUs.forEach((ru) => {
      if (!this.state.selectedProvisionedRUs.includes(ru)) {
        checkAll = false;
      }
    });
    this.setState({
      allChecked: checkAll,
    });
    return displayedRUs;
  };

  getProvisionedUnits = (): RatingUnit[] => {
    let provisionedRUs: RatingUnit[];
    const tenant = this.props.selectedTenant?.tenantId;
    if (tenant != null) {
      provisionedRUs = this.props.ratingUnits.filter((ru) => {
        if (ru.id == null) {
          return false;
        }
        const provisionedIds = this.props.provisionedUnits[tenant];
        if (provisionedIds != null) return provisionedIds.includes(ru.id);
        else return false;
      });
    } else {
      provisionedRUs = [];
    }
    return provisionedRUs;
  };

  updateProvisionedUnit = (unit: RatingUnit): void => {
    if (unit.id != null) {
      if (this.state.selectedProvisionedRUs.includes(unit)) {
        const newProvisionedIds = this.state.selectedProvisionedRUs.filter(
          (ru) => ru.id !== unit.id
        );
        this.setState({
          selectedProvisionedRUs: newProvisionedIds,
        });
      } else {
        this.setState({
          selectedProvisionedRUs: [...this.state.selectedProvisionedRUs, unit],
        });
      }
    }
  };

  filter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      filterText: e.target.value,
    });
  };

  checkAll = (): void => {
    const displayedRUs = this.getDisplayedRatingUnits();
    const currentPUSelection = this.state.selectedProvisionedRUs;
    if (this.state.allChecked) {
      const newProvisionSelection = currentPUSelection.filter(
        (ru) => !displayedRUs.includes(ru)
      );
      this.setState({
        selectedProvisionedRUs: newProvisionSelection,
        allChecked: false,
      });
    } else {
      const newProvisionSelection = [...currentPUSelection, ...displayedRUs];
      this.setState({
        selectedProvisionedRUs: newProvisionSelection,
        allChecked: true,
      });
    }
  };

  resetSelection = (): void => {
    const pus = this.getProvisionedUnits();
    this.setState({
      selectedProvisionedRUs: pus,
    });
    this.getDisplayedRatingUnits();
  };

  selectState = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({
      selectedState: e.target.value,
    });
  };

  selectLob = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({
      selectedLob: e.target.value,
    });
  };

  showReviewScreen = (): void => {
    this.setState({
      showReviewScreen: true,
    });
  };

  hideReviewScreen = (): void => {
    this.setState({
      showReviewScreen: false,
    });
  };

  getRUsToProvision = (): RatingUnit[] => {
    const provisionedUnits = this.getProvisionedUnits();
    const selectedPUs = this.state.selectedProvisionedRUs;

    const rusToProvision = selectedPUs.filter(
      (x) => !provisionedUnits.includes(x)
    );

    return rusToProvision;
  };

  getRUsToUnProvision = (): RatingUnit[] => {
    const provisionedUnits = this.getProvisionedUnits();
    const selectedPUs = this.state.selectedProvisionedRUs;
    const rusToUnprovision = provisionedUnits.filter(
      (x) => !selectedPUs.includes(x)
    );

    return rusToUnprovision;
  };

  saveProvisioning = async (): Promise<void> => {
    this.setState({
      loadingSave: true,
    });
    if (this.props.selectedTenant?.tenantId != null) {
      const tenant = this.props.selectedTenant?.tenantId;
      if (tenant != null) {
        const PUsToSave: ProvisionedUnit[] = [];
        const rusToProvision = this.getRUsToProvision();
        const rusToUnprovision = this.getRUsToUnProvision();
        rusToProvision.forEach((ru) => {
          const provisionedUnit: ProvisionedUnit = {
            entityId: tenant,
            tenantId: tenant,
            ratingUnitId: ru.id,
          };
          PUsToSave.push(provisionedUnit);
        });
        const PUsToDelete: string[] = [];
        rusToUnprovision.forEach((ru) => {
          if (ru.id != null) {
            PUsToDelete.push(ru.id);
          }
        });

        if (PUsToSave.length > 0) {
          await this.props.saveProvisionedUnitsController(tenant, PUsToSave);
        }

        if (PUsToDelete.length > 0) {
          await this.props.deleteProvisionedUnitsController(
            tenant,
            PUsToDelete
          );
        }
      }
    }
    this.hideReviewScreen();
    this.setState({
      loadingSave: false,
    });
  };

  render = (): ReactElement => {
    const stateList = ["ALL", ...getStateStringArray()];
    const provisionedIds = this.state.selectedProvisionedRUs.map((ru) => ru.id);
    const nonNullProvisionedIds: string[] = provisionedIds.filter((id) =>
      notEmpty<string>(id)
    ) as string[];
    const displayedRUs = this.state.displayedRatingUnits;
    return (
      <div>
        <Container fluid>
          {this.props.selectedTenant != null && (
            <div>
              <Row className="tenant-header">
                <div>{this.props.selectedTenant.tenantName}</div>
              </Row>
              <Row>
                <div>TenantID: {this.props.selectedTenant.tenantId}</div>
              </Row>
              <Row>
                <div>AgencyID: {this.props.selectedTenant.ratingAgencyId}</div>
              </Row>
              <Row>
                <Col>
                  <div className="mt-3">
                    <label className="select-label text-orng">State: </label>
                    <select
                      name="states"
                      onChange={(event) => this.selectState(event)}
                      className="customSelect"
                    >
                      {stateList.map((state: string) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    <label className="select-label ml-3 text-orng">
                      Line of Business:{" "}
                    </label>
                    <select
                      name="lineOfBusiness"
                      onChange={(event) => this.selectLob(event)}
                      className="customSelect"
                    >
                      <option value="ALL">All</option>
                      <option value="PERSONAL_AUTO">Personal Auto</option>
                      <option value="HOMEOWNERS">Homeowners</option>
                      <option value="PERSONAL_PACKAGE">Personal Package</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={this.showReviewScreen}>
                    Review Changes
                  </Button>
                </Col>
                <Col>
                  {" "}
                  <Button onClick={this.resetSelection} variant="secondary">
                    Reset Selection{" "}
                  </Button>
                </Col>
              </Row>
              <Row className="form-group has-search">
                <span className="fa fa-search form-control-search"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter"
                  onChange={(event) => this.filter(event)}
                />
              </Row>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      readOnly={false}
                      type="checkbox"
                      label="All"
                      id="all"
                      checked={this.state.allChecked}
                      onClick={this.checkAll}
                    ></Form.Check>
                  </Form>
                </Col>
              </Row>
              {this.state.loading ? (
                <Spinner animation="border" className="spinnerCol" />
              ) : (
                <CompanyView
                  companies={displayedRUs}
                  showProvisioningInfo={true}
                  onCheck={this.updateProvisionedUnit}
                  selectedRatingUnitId={nonNullProvisionedIds}
                  showProvisionedCheckbox={true}
                  onClick={undefined}
                />
              )}
            </div>
          )}
        </Container>
        <ProvisioningReviewComponent
          RUsToUnprovision={this.getRUsToUnProvision()}
          RUsToProvision={this.getRUsToProvision()}
          showModal={this.state.showReviewScreen}
          closeModal={this.hideReviewScreen}
          loading={this.state.loadingSave}
          saveProvisioning={this.saveProvisioning}
        />
      </div>
    );
  };
}

export default connector(ProvisioningCompanySelectComponent);
