/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { definitions } from "../../types/swagger/ratingServiceTypings";
import "./valueList.css";
import "../staticQuestions/common.css";

type ValueListItem = definitions["ValueListItemV1"];
type ValueList = definitions["ValueListV1"];

interface ValueListItemProps {
  valueList: ValueList;
  state: string | undefined;
}

interface ValueListItemState {
  items: ValueListItem[];
}

export default class ValueListItemPopUp extends Component<
  ValueListItemProps,
  ValueListItemState
> {
  constructor(props: ValueListItemProps) {
    super(props);
    this.state = {
      items: props.valueList.items != null ? props.valueList.items : [],
    };
  }

  render = (): ReactElement => {
    return (
      <div>
        <div>
          <p>
            State:{" "}
            {this.props.valueList.state != null
              ? this.props.valueList.state
              : "CW"}
          </p>
          <p>
            Matching Strategy:{" "}
            {this.props.valueList.matchingStrategy != null
              ? this.props.valueList.matchingStrategy
              : "Exact"}
          </p>
        </div>
        <div className="small-header">Value List Items</div>
        <div className="item-list">
          {this.state.items.map((item) => (
            <div key={item.id ?? null} className="item-list-entry">
              {item.value}
            </div>
          ))}
        </div>
      </div>
    );
  };
}
