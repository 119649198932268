/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { definitions } from "../../../types/swagger/ratingServiceTypings";

export type RatingArtifact = definitions["RatingArtifactV1"];

export enum ActionTypesEnum {
  GET_ARTIFACTS_BY_CID = "GET_ARTIFACTS_BY_CID",
}

export interface RatingArtifactState {
  ratingArtifacts: { [correlationId: string]: RatingArtifact[] };
}

export interface GetRatingArtifactsForCIDAction {
  type: typeof ActionTypesEnum.GET_ARTIFACTS_BY_CID;
  payload: { correlationId: string; ratingArtifacts: RatingArtifact[] };
}

export type RatingArtifactsActionTypes = GetRatingArtifactsForCIDAction;
