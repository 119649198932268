/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { definitions } from "../../types/swagger/ratingServiceTypings";
import ValueListItemPopUp from "./valueListItem";
import "./valueList.css";
import Spinner from "react-bootstrap/Spinner";
import Collapsible from "../collapsible/collapsible";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import ReactPaginate from "react-paginate";

type ValueList = definitions["ValueListV1"];

interface ValueListProps {
  ratingDataId: string;
  searchState?: string;
}

interface ValueListState {
  valueLists: ValueList[];
  searchText: string;
  selectedValueList: ValueList | null;
  loading: boolean;
  numPages: number;
  currentPage: number;
  pageSize: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  staticQuestionFRD: state.staticQuestions.staticQuestionFRD,
  packets: state.ratingDataPackets.packets,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ValueListProps;

class ValueListComponent extends Component<Props, ValueListState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      valueLists: [],
      searchText: "",
      selectedValueList: null,
      loading: true,
      numPages: 1,
      currentPage: 1,
      pageSize: 20,
    };
  }

  getNumPages = (): void => {
    const numPages = Math.ceil(
      this.getFilteredValueLists().length / this.state.pageSize
    );

    if (this.state.numPages !== numPages) {
      this.setState({
        numPages: numPages,
      });
    }
  };

  componentDidMount = async (): Promise<void> => {
    await this.loadValueLists();
  };

  componentDidUpdate = async (prevProps: ValueListProps): Promise<void> => {
    if (prevProps.ratingDataId !== this.props.ratingDataId) {
      await this.loadValueLists();
    }
  };

  loadValueLists = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.pullValueLists();
    this.getNumPages();
  };

  handlePageSelect = (selectedPage: { selected: number }): void => {
    this.setState({
      currentPage: selectedPage.selected + 1,
    });
  };

  pullValueLists = async (): Promise<void> => {
    this.setState({
      loading: false,
      valueLists:
        this.props.packets
          .filter(
            (packet) =>
              packet.ratingData != null &&
              packet.ratingData.id === this.props.ratingDataId
          )
          .map((packet) => packet.ratingData?.values)
          .find((valueList) => valueList != null) ?? [],
    });
  };

  updateSearchText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      searchText: e.target.value,
    });
  };

  getStateValueLists = (): ValueList[] => {
    if (this.props.searchState !== "") {
      let vLists = this.state.valueLists.filter((valuelist) => {
        return (
          valuelist.state !== null &&
          this.props.searchState != null &&
          valuelist.state === this.props.searchState
        );
      });
      if (vLists.length === 0) {
        vLists = this.getCWList();
      }
      return vLists;
    }
    return this.state.valueLists;
  };

  getCWList = (): ValueList[] => {
    return this.state.valueLists.filter((valuelist) => {
      return valuelist.state === null;
    });
  };

  getPagedValueLists = (valList: ValueList[]): ValueList[] => {
    const spliceStart: number =
      (this.state.currentPage - 1) * this.state.pageSize;
    const spliceEnd: number =
      this.state.currentPage !== this.state.numPages
        ? spliceStart + this.state.pageSize
        : valList.length;
    return valList.slice(spliceStart, spliceEnd);
  };

  getFilteredValueLists = (): ValueList[] => {
    const valLists = this.getStateValueLists();
    if (this.state.searchText === "") {
      return this.getPagedValueLists(valLists);
    }
    const result: ValueList[] = valLists.filter((list) => {
      return list.name
        .toLowerCase()
        .includes(this.state.searchText.toLowerCase());
    });
    return this.getPagedValueLists(result);
  };

  closeModal = (): void => {
    this.setState({
      selectedValueList: null,
    });
  };

  render = (): ReactElement => {
    return (
      <div className="value-list-container">
        <div className="search-div">
          <div className="form-group has-search">
            <span className="fa fa-search form-control-search"></span>
            <input
              className="form-control"
              type="text"
              onChange={this.updateSearchText}
              placeholder="Filter value list names..."
            />
          </div>
        </div>

        {this.state.loading ? (
          <div className="valueList-spinner">
            {" "}
            <Spinner animation="border" size="sm" className="spinnerCol" />
          </div>
        ) : (
          <div>
            <div className="value-lists">
              {this.state.valueLists.length === 0 ? (
                <p className="text-center mt-2 text-muted">
                  No value lists to display
                </p>
              ) : (
                this.getFilteredValueLists().map((list) => (
                  <Collapsible
                    key={list.id ?? null}
                    title={list.name}
                    collapseType="inner"
                  >
                    <ValueListItemPopUp
                      valueList={list}
                      state={this.props.searchState}
                    />
                  </Collapsible>
                ))
              )}
            </div>

            {this.state.loading
              ? ""
              : this.state.numPages > 1 && (
                  <div className="pagination-margin">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={this.state.numPages}
                      onPageChange={this.handlePageSelect}
                      previousLinkClassName={"page-item page-link text-orng"}
                      nextLinkClassName={"page-item page-link text-orng"}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link text-orng"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      activeLinkClassName={"active-page"}
                      pageRangeDisplayed={4}
                      marginPagesDisplayed={1}
                    />
                  </div>
                )}
          </div>
        )}
      </div>
    );
  };
}

export default connector(ValueListComponent);
