/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { definitions } from "../../types/swagger/ratingServiceTypings";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";

import "../staticQuestions/common.css";
import "./rules.css";
import Spinner from "react-bootstrap/Spinner";
import Collapsible from "../collapsible/collapsible";
import ReactPaginate from "react-paginate";

type Rule = definitions["RuleV1"];

interface RulesProps {
  ratingDataId: string;
  selectedState: string;
}
interface RulesState {
  rules: Rule[];
  loading: boolean;
  numPages: number;
  currentPage: number;
  pageSize: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  packets: state.ratingDataPackets.packets,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RulesProps;

class RulesComponent extends Component<Props, RulesState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rules: [],
      loading: false,
      currentPage: 1,
      pageSize: 20,
      numPages: 1,
    };
  }

  handlePageSelect = (selectedPage: { selected: number }): void => {
    this.setState({
      currentPage: selectedPage.selected + 1,
    });
  };

  getNumPages = (): void => {
    const numPages = Math.ceil(
      this.getFilteredRules().length / this.state.pageSize
    );

    if (this.state.numPages !== numPages) {
      this.setState({
        numPages: numPages,
      });
    }
  };

  getPagedRules = (): Rule[] => {
    let pagedCompanies: Rule[];
    this.getNumPages();
    if (this.getFilteredRules().length > 1) {
      pagedCompanies = this.getFilteredRules()?.sort((rule1, rule2) => {
        if (rule1.state == null && rule2.state == null) return 0;
        else if (rule1.state == null) return -1;
        else if (rule2.state == null) return 1;
        else if (rule1.state < rule2.state) return -1;
        else if (rule1.state > rule2.state) return 1;
        return 0;
      });
      const spliceStart: number =
        (this.state.currentPage - 1) * this.state.pageSize;
      const spliceEnd: number =
        this.state.currentPage !== this.state.numPages
          ? spliceStart + this.state.pageSize
          : pagedCompanies.length;
      return pagedCompanies.slice(spliceStart, spliceEnd);
    } else {
      pagedCompanies = this.getFilteredRules();
      return pagedCompanies;
    }
  };

  componentDidMount = async (): Promise<void> => {
    await this.loadRules();
    this.getNumPages();
  };

  componentDidUpdate = async (prevProps: RulesProps): Promise<void> => {
    if (prevProps.ratingDataId !== this.props.ratingDataId) {
      await this.loadRules();
      this.getNumPages();
    }
  };

  loadRules = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.pullRules();
  };

  pullRules = async (): Promise<void> => {
    if (
      this.props.ratingDataId !== undefined &&
      this.props.ratingDataId !== ""
    ) {
      this.setState({
        rules: this.getFilteredRules(),
        loading: false,
      });
    }
  };

  sort = (rules: Rule[]): number => {
    if (rules.length > 1) {
      rules = rules.sort((rule1, rule2) => {
        if (rule1.processSequence == null && rule2.processSequence != null) {
          return 0;
        }
        if (rule1.processSequence == null) {
          return -1;
        }
        if (rule2.processSequence == null) {
          return 1;
        }
        return rule1.processSequence - rule2.processSequence;
      });
    }
    return 0;
  };

  getFilteredRules = (): Rule[] => {
    if (this.props.packets != null) {
      let result =
        this.props.packets
          .filter((packet) => {
            return (
              packet.ratingData != null &&
              packet.ratingData.id === this.props.ratingDataId
            );
          })
          .map((packet) => packet.ratingData?.rules)
          .find((rule) => rule != null) ?? [];
      if (this.props.selectedState !== "") {
        result = result.filter(
          (rule) => rule.state === this.props.selectedState
        );
      }
      return result;
    }
    return [];
  };

  render = (): ReactElement => {
    const pagedRules = this.getPagedRules();

    return (
      <div className="rules-container">
        {this.state.loading ? (
          <div className="rules-spinner">
            <Spinner animation="border" className="spinnerCol" size="sm" />
          </div>
        ) : (
          <div>
            <div className="rules-list">
              {pagedRules?.length > 0 ? (
                pagedRules
                  .filter((rule) => rule.id != null)
                  .map((rule) => (
                    <Collapsible
                      collapseType="inner"
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      key={rule.id ?? null}
                      title={rule.formula ?? "No formula for this rule"}
                    >
                      <div>
                        <div>
                          <p>State: {rule.state}</p>
                          <p>Process Sequence: {rule.processSequence}</p>
                        </div>
                        <div className="rule-details">
                          <h5>Expression</h5>
                          <div className="codeSection">
                            <div className="expression">
                              <pre>
                                {JSON.stringify(rule.expression, null, 2)}
                              </pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  ))
              ) : (
                <p className="text-center">
                  No rules exist for this Rating Data
                </p>
              )}
              {this.state.loading
                ? ""
                : this.state.numPages > 1 && (
                    <div className="pagination-margin">
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={this.state.numPages}
                        onPageChange={this.handlePageSelect}
                        previousLinkClassName={"page-item page-link text-orng"}
                        nextLinkClassName={"page-item page-link text-orng"}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link text-orng"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        activeLinkClassName={"active-page"}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                      />
                    </div>
                  )}
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default connector(RulesComponent);
