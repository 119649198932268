/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type RatingUnit = definitions["RatingUnitV1"];

export enum ActionTypesEnum {
  SET_RATING_UNITS = "SET_RATING_UNITS",
  SET_PROVISIONED_UNITS_FOR_TENANT = "SET_PROVISIONED_UNITS_FOR_TENANT",
  REMOVE_PROVISIONED_UNITS = "REMOVE_PROVISIONED_UNITS",
  SAVE_PROVISIONED_UNITS = "SAVE_PROVISIONED_UNITS",
}

export interface RatingUnitState {
  ratingUnits: RatingUnit[];
  provisionedUnits: { [tenant: string]: string[] };
}

export interface SetRatingUnitAction {
  type: typeof ActionTypesEnum.SET_RATING_UNITS;
  payload: RatingUnit[];
}

export interface SetProvisionedUnitForTenantAction {
  type: typeof ActionTypesEnum.SET_PROVISIONED_UNITS_FOR_TENANT;
  payload: { tenant: string; ids: string[] };
}

export interface RemoveProvisionedUnitsAction {
  type: typeof ActionTypesEnum.REMOVE_PROVISIONED_UNITS;
  payload: { tenant: string; ids: string[] };
}

export interface SaveProvisionedUnitsAction {
  type: typeof ActionTypesEnum.SAVE_PROVISIONED_UNITS;
  payload: { tenant: string; ids: string[] };
}

export type RatingUnitActionTypes =
  | SetRatingUnitAction
  | SetProvisionedUnitForTenantAction
  | RemoveProvisionedUnitsAction
  | SaveProvisionedUnitsAction;
