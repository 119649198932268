/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { Container, Col, Row, Modal, Button, Spinner } from "react-bootstrap";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import {
  getRatingUnitsController,
  getProvisionedUnitsByTenantController,
} from "../../app/store/ratingUnits/controller";

import { definitions } from "../../types/swagger/ratingServiceTypings";
import CompanyView from "../companyQuestions/companyView";

type RatingUnit = definitions["RatingUnitV1"];

interface Props {
  RUsToUnprovision: RatingUnit[];
  RUsToProvision: RatingUnit[];
  showModal: boolean;
  loading: boolean;
  closeModal: () => void;
  saveProvisioning: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  ratingUnits: state.ratingUnits.ratingUnits,
  provisionedUnits: state.ratingUnits.provisionedUnits,
});

const mapDispatchToProps = {
  getRatingUnitsController,
  getProvisionedUnitsByTenantController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FinalProps = PropsFromRedux & Props;

class ProvisioningReviewComponent extends Component<FinalProps> {
  render = (): ReactElement => {
    return (
      <Modal size="xl" show={this.props.showModal}>
        {this.props.loading ? (
          <Spinner animation="border" className="spinnerCol" />
        ) : (
          <Container fluid>
            <Row>
              <Col>
                <Row>Units to Provision</Row>
                <Row>
                  <CompanyView
                    showProvisioningInfo={true}
                    selectedRatingUnitId={undefined}
                    onClick={undefined}
                    onCheck={undefined}
                    companies={this.props.RUsToProvision}
                    showProvisionedCheckbox={false}
                  />
                </Row>
              </Col>
              <Col>
                <Row>Units to Un-Provision</Row>
                <Row>
                  <CompanyView
                    showProvisioningInfo={true}
                    selectedRatingUnitId={undefined}
                    onClick={undefined}
                    onCheck={undefined}
                    companies={this.props.RUsToUnprovision}
                    showProvisionedCheckbox={false}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        )}
        <Button
          variant="secondary"
          onClick={this.props.closeModal}
          active={!this.props.loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={this.props.saveProvisioning}
          active={!this.props.loading}
        >
          Save
        </Button>
      </Modal>
    );
  };
}

export default connector(ProvisioningReviewComponent);
