/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import HierarchyComponent from "../../components/staticQuestions/hierarchy";
import RatingDataComponent from "../../components/flatRatingData/flatRatingData";
import CompanyQuestionPage from "../../components/companyQuestions/companyQuestionPage";

import "./apiReference.css";
import { STATES, getStateStringArray } from "../../types/enums/states";
import { Col, Row, Tabs, Tab } from "react-bootstrap";

import {
  ApiPropertyNode,
  ApiTypeNode,
} from "../../components/accordionTree/accordionTree";

interface HierarchyState {
  selectedState: STATES;
  selectedLob: string;
  selectedRatingData: string;
  selectedNode: ApiTypeNode | ApiPropertyNode | undefined;
  allStatesSelected: boolean;
  showRightPanel: boolean;
  selectedPropertyName: string;
  selectedPropertyType: string;
}
class ApiReferenceComponent extends Component<unknown, HierarchyState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      selectedLob: "PERSONAL_AUTO",
      selectedState: STATES.ALABAMA,
      selectedRatingData: "",
      selectedNode: undefined,
      allStatesSelected: true,
      showRightPanel: true,
      selectedPropertyName: "",
      selectedPropertyType: "",
    };
  }

  showRightPanel = (clicked: boolean): void => {
    this.setState({ showRightPanel: true });
  };

  selectRatingData = (
    apiPropertyName: string,
    apiPropertyType: string,
    ratingDataId: string,
    lineOfBusiness: string,
    selectedNode: ApiTypeNode | ApiPropertyNode | undefined
  ): void => {
    this.setState({
      ...this.state,
      selectedPropertyName: apiPropertyName,
      selectedPropertyType: apiPropertyType,
      selectedRatingData: ratingDataId,
      selectedLob: lineOfBusiness,
      selectedNode: selectedNode,
    });
  };

  selectState = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ showRightPanel: false });

    if (e.target.value === "All") {
      this.setState({
        allStatesSelected: true,
      });
    } else {
      this.setState({
        selectedState: e.target.value as STATES,
        allStatesSelected: false,
      });
    }
  };

  selectLob = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({
      selectedLob: e.target.value,
      allStatesSelected: false,
    });
  };

  render = (): ReactElement => {
    const selectedState = this.state.selectedState;
    const stateList: string[] = getStateStringArray();
    return (
      <div className="hierarchyContainer">
        <Tabs
          defaultActiveKey="static"
          id="uncontrolled-tab-example text-center"
        >
          <Tab eventKey="static" title="Static Questions">
            <div className="mt-3">
              <label className="select-label text-orng">State: </label>
              <select
                name="states"
                onChange={(event) => this.selectState(event)}
                className="customSelect"
                value={this.state.allStatesSelected ? "All" : selectedState}
              >
                {["All", ...stateList].map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Row>
                <Col>
                  <HierarchyComponent
                    currentLOB={this.state.selectedLob}
                    currentState={
                      this.state.allStatesSelected
                        ? "All"
                        : this.state.selectedState
                    }
                    selectRatingData={this.selectRatingData}
                    selectedRatingData={this.state.selectedRatingData}
                    allStatesSelected={this.state.allStatesSelected}
                    showRightPanel={this.showRightPanel}
                  />
                </Col>
                {this.state.showRightPanel ? (
                  <Col className="w-698">
                    <RatingDataComponent
                      selectedRatingData={this.state.selectedRatingData}
                      selectedNode={this.state.selectedNode}
                      collapseType="outer"
                      selectedState={
                        this.state.allStatesSelected
                          ? "All"
                          : this.state.selectedState
                      }
                    />
                  </Col>
                ) : (
                  <Col className="text-center mt-5">
                    Please click on any property to view details.
                  </Col>
                )}
              </Row>
            </div>
          </Tab>
          <Tab eventKey="company" title="Company Questions" className="p-0">
            <div className="mt-3">
              <label className="select-label text-orng">State: </label>
              <select
                name="states"
                onChange={(event) => this.selectState(event)}
                className="customSelect"
                value={selectedState}
              >
                {stateList.map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>

              <label className="select-label ml-3 text-orng">
                Line of Business:{" "}
              </label>
              <select
                name="lineOfBusiness"
                onChange={(event) => this.selectLob(event)}
                className="customSelect"
              >
                <option value="PERSONAL_AUTO">PERSONAL_AUTO</option>
                <option value="HOMEOWNERS">HOMEOWNERS</option>
                <option value="RENTERS">RENTERS</option>
                <option value="CONDO">CONDO</option>
                <option value="PERSONAL_PACKAGE">PERSONAL_PACKAGE</option>
              </select>
            </div>
            <CompanyQuestionPage
              state={this.state.selectedState}
              lineOfBusiness={this.state.selectedLob}
            />
          </Tab>
        </Tabs>
      </div>
    );
  };
}

export default ApiReferenceComponent;
