/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { definitions } from "../../types/swagger/ratingServiceTypings";
import { Container, Col, Row } from "react-bootstrap";

type RatingUnit = definitions["RatingUnitV1"];

interface Props {
  ratingUnit: RatingUnit | null;
}

export default class RatingUnitDetailsComponent extends Component<Props> {
  render = (): ReactElement => {
    return (
      <Container fluid id="company_details" className="m-2">
        <Row>
          <Col className="details_header wide">
            <h5>{this.props.ratingUnit?.name}</h5>
          </Col>
        </Row>
        <Row className="text-orng">
          <Col className="col-3">Rating Unit Id</Col>
          <Col> : {this.props.ratingUnit?.id ?? "N/A"}</Col>
        </Row>
        <Row className="text-orng">
          <Col className="col-3">Line of Business</Col>
          <Col> : {this.props.ratingUnit?.lineOfBusiness ?? "N/A"}</Col>
        </Row>
        <Row className="text-orng">
          <Col className="col-3">State</Col>
          <Col> : {this.props.ratingUnit?.state ?? "N/A"}</Col>
        </Row>
        <Row className="text-orng">
          <Col className="col-3">Company Id</Col>
          <Col> : {this.props.ratingUnit?.companyId ?? "N/A"}</Col>
        </Row>
        <Row className="text-orng">
          <Col className="col-3">Plan Id</Col>
          <Col> : {this.props.ratingUnit?.planId ?? "N/A"}</Col>
        </Row>
      </Container>
    );
  };
}
