/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import Spinner from "react-bootstrap/Spinner";
import Collapsible from "../collapsible/collapsible";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import { addCompanyQuestionsByRatingUnitController } from "../../app/store/companyQuestions/controller";
import RatingDataComponent from "../flatRatingData/flatRatingData";
import "./ratingUnitRatingData.css";
import { definitions } from "../../types/swagger/ratingServiceTypings";

type FlatRatingDataV1 = definitions["FlatRatingDataV1"];
interface RURDProps {
  ratingUnitId: number;
  selectedLob: string;
}

interface RURDState {
  companyQuestions: FlatRatingDataV1[];
  searchText: string;
  selectedFlatRatingData: FlatRatingDataV1 | null;
  loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  companyQuestions: state.companyQuestions.companyQuestionFRD,
});

const mapDispatchToProps = {
  addCompanyQuestionsByRatingUnitController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RURDProps;

class RatingUnitRatingDataComponent extends Component<Props, RURDState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      companyQuestions: [],
      searchText: "",
      selectedFlatRatingData: null,
      loading: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    await this.loadCompanyQuestions();
  };

  componentDidUpdate = async (prevProps: RURDProps): Promise<void> => {
    if (prevProps.ratingUnitId !== this.props.ratingUnitId) {
      await this.loadCompanyQuestions();
    }
  };

  loadCompanyQuestions = async (): Promise<void> => {
    this.setState({
      ...this.state,
      selectedFlatRatingData: null,
      loading: true,
    });
    await this.pullCompanyQuestions();
  };

  pullCompanyQuestions = async (): Promise<void> => {
    if (this.props.ratingUnitId !== 0) {
      await this.props
        .addCompanyQuestionsByRatingUnitController(this.props.ratingUnitId)
        .then(() => {
          this.setState({
            companyQuestions: this.props.companyQuestions.filter(
              (cq) => cq.ratingUnitId === this.props.ratingUnitId
            ),
          });
        });
      this.setState({
        loading: false,
      });
    }
  };

  updateSearchText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      searchText: e.target.value,
    });
  };

  getDisplayedCompanyQuestions = (): FlatRatingDataV1[] => {
    if (this.state.searchText === "") {
      return this.state.companyQuestions;
    }
    const result: FlatRatingDataV1[] = this.state.companyQuestions.filter(
      (cq) => {
        return (
          cq.label
            .toLocaleLowerCase()
            .includes(this.state.searchText.toLocaleLowerCase()) ||
          cq.tag
            .toLocaleLowerCase()
            .includes(this.state.searchText.toLocaleLowerCase())
        );
      }
    );

    return result;
  };

  render = (): ReactElement => {
    return (
      <div className="rurd-container">
        {this.state.companyQuestions.length > 0 ? (
          <div className="form-group has-search">
            <span className="fa fa-search form-control-search"></span>
            <input
              type="text"
              onChange={this.updateSearchText}
              placeholder="Filter company questions"
              className="form-control"
            />
          </div>
        ) : (
          ""
        )}
        {this.state.loading ? (
          <div>
            {" "}
            <Spinner animation="border" className="loader" />
          </div>
        ) : (
          <div>
            <div className="rurd">
              {this.state.companyQuestions.length === 0 ? (
                <p className="text-center mt-2 text-muted">
                  No company questions to display
                </p>
              ) : (
                this.getDisplayedCompanyQuestions()
                  .filter(
                    (cq) =>
                      cq.ratingDataId != null &&
                      cq.lineOfBusiness === this.props.selectedLob
                  )
                  .map((cq) => (
                    <Collapsible
                      key={cq.id ?? null}
                      title={cq.label}
                      collapseType="uppermiddle"
                    >
                      <RatingDataComponent
                        // No null assertion is safe, we filtered where rdId is not null
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        selectedRatingData={cq.ratingDataId!}
                        selectedNode={undefined}
                        collapseType="middle"
                        selectedState=""
                      />
                    </Collapsible>
                  ))
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default connector(RatingUnitRatingDataComponent);
