/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import { ActionTypesEnum, StaticQuestionActionTypes } from "./types";
import { definitions } from "../../../types/swagger/ratingServiceTypings";

type FlatRatingDataV1 = definitions["FlatRatingDataV1"];
type JsonHierarchy = definitions["JsonHierarchy"];

export function setStaticQuestionsAction(
  staticQuestionList: FlatRatingDataV1[]
): StaticQuestionActionTypes {
  return {
    type: ActionTypesEnum.SET_STATIC_QUESTIONS,
    payload: staticQuestionList,
  };
}

export function setStaticQuestionStateLinkIdsAction(
  state: string,
  staticQuestionStateLinkIds: string[]
): StaticQuestionActionTypes {
  return {
    type: ActionTypesEnum.SET_STATIC_QUESTION_STATE_LINK_IDS,
    payload: {
      state: state,
      staticQuestionStateLinkIds: staticQuestionStateLinkIds,
    },
  };
}

export function setJsonHierarchy(
  hierarchy: JsonHierarchy[]
): StaticQuestionActionTypes {
  return {
    type: ActionTypesEnum.SET_JSON_HIERARCHY,
    payload: hierarchy,
  };
}
